@font-face {
  font-family: "Montserrat Black";
  src: url("../fonts/Montserrat-Black.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Black Italic";
  src: url("../fonts/Montserrat-BlackItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Bold";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Bold Italic";
  src: url("../fonts/Montserrat-BoldItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Extra Bold";
  src: url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Extra Bold Italic";
  src: url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Extra Light";
  src: url("../fonts/Montserrat-ExtraLight.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Extra Light Italic";
  src: url("../fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Italic";
  src: url("../fonts/Montserrat-Italic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Light";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Light Italic";
  src: url("../fonts/Montserrat-LightItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Medium";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Medium Italic";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Regular";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Semi Bold";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Semi Bold Italic";
  src: url("../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Thin";
  src: url("../fonts/Montserrat-Thin.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Montserrat Thin Italic";
  src: url("../fonts/Montserrat-ThinItalic.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
